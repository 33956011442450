import React, { createContext, useContext, useState } from "react";
import { OnboardingContentModel } from "../../hooks/onboarding/OnboardingContentModel";
import { useGetOnboarding } from "../../hooks/onboarding/UseOnboarding";
import useAnalytics, { LinkOrigin, LinkSubOrigin } from "../../hooks/analytics/UseAnalytics";
import { useLanguage } from "../../hooks/language/useLanguage";
import { TeamsFxContext } from "../../components/Context";
import { AzureFunctions } from "../../services/backend/AzureFunctions";
import { useCoachConnection } from "../../hooks/coach-connector/useCoachConnection";

interface IOnboardingContextType {
    currentPage: number;
    setCurrentPage: (step: number) => void;
    hasNextPage: () => boolean;
    nextPage: () => void;
    hasPreviousPage: () => boolean;
    previousPage: () => void;
    isLoading: boolean;
    onboarding?: OnboardingContentModel;
    getNumberOfSteps: () => number;
    isNextStepLoading: boolean;
    isIntakeLoading: boolean;
}

interface Props {
    children: React.ReactNode;
}

const OnboardingContext = createContext<IOnboardingContextType | undefined>(undefined);

export const OnboardingProvider: React.FC<Props> = ({ children }) => {
    const { teamsUserCredential } = useContext(TeamsFxContext);

    const initialStep = 0;

    const [currentPage, setCurrentPage] = useState(initialStep);
    const [isNextStepLoading, setIsNextStepLoading] = useState(false);
    const [isIntakeLoading, setIsIntakeLoading] = useState(false);

    const { data: onboarding, isLoading: isOnboardingLoading, error: onboardingError } = useGetOnboarding();
    const { trackLinkClicked } = useAnalytics();
    const { getCurrentLanguage } = useLanguage();
    const { startIntake } = useCoachConnection();

    const isLoading = isOnboardingLoading;

    // TODO - properly handle error
    const error = onboardingError;
    if (error) {
        throw error;
    }

    const askForConsent = async () => {
        try {
            const azureFunctions = new AzureFunctions(teamsUserCredential!);
            try {
                await azureFunctions.getUserInfo();
            } catch (ex) {
                await teamsUserCredential!.login(["User.Read"]);
            }

            return true;
        } catch (ex) {
            console.warn(ex);
            return false; // Don't continue if the user didn't consent.
        }
    }

    const launchCoachIntake = async () => {
        const locale = getCurrentLanguage();
        setIsNextStepLoading(true);

        if (!teamsUserCredential) {
            throw new Error("TeamsFx SDK is not initialized.");
        }

        await startIntake(locale);
        setIsIntakeLoading(true);

        // TEMP: Disabled as Teams chat crashes when deeplinked to. See: https://github.com/OfficeDev/microsoft-teams-library-js/issues/2441.
        trackLinkClicked({ destination: LinkOrigin.Conversations, subDestination: LinkSubOrigin.Intake, source: LinkOrigin.App, subSource: LinkSubOrigin.Intake, });
        // pages.currentApp.navigateTo({ pageId: "conversations" });
    }

    const hasNextPage = () => {
        return currentPage < getNumberOfSteps()! - 1;
    }

    const nextPage = async () => {
        if (!hasNextPage()) {
            const grantedConsent = await askForConsent();
            if (grantedConsent) {
                launchCoachIntake();
            }
            return;
        }

        setCurrentPage((prev) => prev + 1);
    }

    const hasPreviousPage = () => {
        return currentPage > 0;
    }

    const previousPage = () => {
        setCurrentPage((prev) => prev - 1);
    }

    const getNumberOfSteps = () => {
        return onboarding?.steps.length || 0;
    }

    const value: IOnboardingContextType = {
        currentPage,
        setCurrentPage,
        hasNextPage,
        nextPage,
        hasPreviousPage,
        previousPage,
        isLoading,
        onboarding,
        getNumberOfSteps,
        isNextStepLoading,
        isIntakeLoading,
    };

    return (
        <OnboardingContext.Provider value={value}>
            {children}
        </OnboardingContext.Provider>
    );
}

export const useOnboardingContext = () => {
    const context = useContext(OnboardingContext);
    if (context === undefined) {
        throw new Error('useOnboardingContext must be used within a OnboardingProvider');
    }

    return context;
}