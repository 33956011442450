import { Button, Image } from "react-bootstrap";

import { LessonCoachPractisePageModel } from "../../hooks/lesson/LessonContentModel";
import LessonMarkdownContent from "./markdown/LessonMarkdownContent";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import useAnalytics, { LinkOrigin, LinkSubOrigin } from "../../hooks/analytics/UseAnalytics";
import { useCoachConnection } from "../../hooks/coach-connector/useCoachConnection";
import { useGetCoachPracticeReferences } from "../../hooks/coach-practices/UseCoachPractices";
import { useLanguage } from "../../hooks/language/useLanguage";
import { CommonLoading } from "../loading/CommonLoading";
import { NoticePopup } from "../notice-popup/NoticePopup";
import "./LessonCoachPractisePage.css";

interface Props {
  content: LessonCoachPractisePageModel;
}

export default function LessonCoachPractisePage(props: Props) {
  const [isCoachChatButtonClicked, setIsCoachChatButtonClicked] = useState(false);
  const [isCoachChatReady, setIsCoachChatReady] = useState(false);
  const { trackLinkClicked } = useAnalytics();
  const { getCurrentLanguage } = useLanguage();
  const { t } = useTranslation();
  const locale = getCurrentLanguage();
  const {
    data: coachPractices,
    isLoading: coachPracticeReferencesLoading,
    error: fetchCoachPracticeReferencesError
  } = useGetCoachPracticeReferences(locale);

  const { startPractice } = useCoachConnection();

  const openCoachChat = async () => {
    setIsCoachChatButtonClicked(true);

    const version = coachPractices?.find(practice => practice.id === props.content.coachPractice.id)?.version || 0;
    await startPractice(props.content.coachPractice, locale, version);
    setIsCoachChatReady(true);

    trackLinkClicked({ destination: LinkOrigin.Conversations, subDestination: LinkSubOrigin.Practice, source: LinkOrigin.App, subSource: LinkSubOrigin.Lesson, });

    // TEMP: Disabled as Teams chat crashes when deeplinked to. See: https://github.com/OfficeDev/microsoft-teams-library-js/issues/2441.
    // pages.currentApp.navigateTo({ pageId: 'conversations' });
  }

  return (
    <>
      { coachPracticeReferencesLoading ? (
        <CommonLoading />
      ) : fetchCoachPracticeReferencesError ? (
        <b>
          { t('common.error') }
        </b>
      ) : (
        <div>
          <h1>{props.content.header}</h1>
          <LessonMarkdownContent content={props.content.body} />
          <Button variant="coach" onClick={openCoachChat} disabled={isCoachChatButtonClicked}>
            { t('lesson.coachPracticeButton') }
            <Image src="lesson/coach-button-icon.png" />
          </Button>
          <NoticePopup headerTranslationKey="chatRedirection.header" bodyTranslationKey="chatRedirection.body" isBlocking={true} show={isCoachChatReady} />
        </div>
      )}
    </>
  )
}
