import React from 'react'
import './DashboardStepper.css'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

export interface IDashboardStepperProps {
    steps: {
        id: number;
        label: string;
        isAvailable: boolean;
    }[];
    currentStepIndex: number;
    onStepPressed: (index: number) => void;
}

export const DashboardStepper: React.FC<IDashboardStepperProps> = ({ currentStepIndex, onStepPressed, steps }) => {
    const { t } = useTranslation();

    const getStepClassName = (index: number) => {
        if (index < 0 || index >= steps.length) {
            return 'step-unavailable';
        }

        const isAvailable = steps[index].isAvailable;
        const isSelected = index === currentStepIndex;
        return `${isSelected ? 'step-selected' : 'step-unselected'} ${isAvailable ? 'step-available' : ''}`;
    }

    const getStepLineClassName = (index: number) => {
        const isAvailable = steps[index].isAvailable;
        return isAvailable ? 'step-line step-line-available' : 'step-line';
    }

    const getStepLabel = (index: number, step: string) => {
        if (isMobile) {
            if (index < currentStepIndex) {
                return "<";
            } else if (index > currentStepIndex) {
                return ">";
            } else {
                return t('dashboard.stepper.currentStep', { step });
            }
        }

        return index === currentStepIndex ? t('dashboard.stepper.currentStep', { step }) : step;
    }


    if (isMobile) {
        return (
            <div className='stepper'>
                <div className='step'>
                    <motion.button
                        onClick={() => onStepPressed(currentStepIndex - 1)}
                        disabled={ currentStepIndex <= 0}
                        className='step-navigate'>
                        &lt;
                    </motion.button>
                </div>

                <div key={currentStepIndex} className='step'>
                    <motion.button
                        key={currentStepIndex}
                        className={getStepClassName(currentStepIndex)}
                        animate={{
                            transition: { duration: 0.5 },
                        }}
                        onClick={() => onStepPressed(currentStepIndex)}
                    >
                        {getStepLabel(currentStepIndex, steps[currentStepIndex].label)}
                    </motion.button>
                </div>

                <div className='step'>
                    <motion.button
                        onClick={() => onStepPressed(currentStepIndex + 1)}
                        disabled={ currentStepIndex >= steps.length - 1}
                        className='step-navigate'>
                        &gt;
                    </motion.button>
                </div>
            </div>
        );
    } else {
        return (
            <div className='stepper'>
                {steps.map((step, index) => (
                    <div key={index} className='step'>
                        {index > 0 && (
                            <div className={getStepLineClassName(index)} />
                        )}
                        <motion.button
                            className={getStepClassName(index)}
                            animate={{
                                transition: { duration: 0.5 },
                            }}
                            onClick={() => onStepPressed(index)}
                        >
                            {getStepLabel(index, step.label)}
                        </motion.button>
                    </div>
                ))}
            </div>
        )
    }
}
