import axios from 'axios';

import Config from "../../config";
import { CoachPracticeReference } from '../../hooks/lesson/LessonContentModel';
import { TeamsUserCredential } from '@microsoft/teamsfx';

class CoachConnector {
  private practiceStartPath = "/api/startPractice";
  private intakeStartPath = "/api/startIntake";
  private sendWelcomeMessagePath = "/api/welcomeUser";
  private sendUnlicensedMessagePath = "/api/sendUnlicensedMessageToUser";

  private debugForgetUserPath = "/api/debugForgetUser";

  private welcomeMessageSentKey = "welcomeMessageSent";
  private unlicensedMessageSentKey = "unlicensedMessageSent";

  private botEndpoint: string;
  private credential: TeamsUserCredential;

  constructor(credential: TeamsUserCredential) {
    this.botEndpoint = Config.botEndpoint;
    this.credential = credential;
  }

  async startPractice(coachPractice: CoachPracticeReference, locale: string, version: number): Promise<void> {
    const startPracticeUrl = new URL(this.practiceStartPath, this.botEndpoint).toString();

    return axios.post(startPracticeUrl, {
      coachPracticeId: coachPractice.id,
      locale: locale,
      version: version,
    }, {
      headers: await this.getHeaders()
    });
  }

  async startIntake(locale: string): Promise<void> {
    const startIntakeUrl = new URL(this.intakeStartPath, this.botEndpoint).toString();

    return axios.post(startIntakeUrl, {
      locale: locale,
    }, {
      headers: await this.getHeaders()
    });
  }

  async sendUnlicensedMessageToUserIfNotSentPreviously(locale: string): Promise<void> {
    if (localStorage.getItem("unlicensedMessageSent")?.toLowerCase() === true.toString().toLowerCase()) {
      return;
    }

    await this.sendUnlicensedMessageToUser(locale);

    localStorage.setItem("unlicensedMessageSent", true.toString());
  }

  async sendUnlicensedMessageToUser(locale: string, forceSending: boolean = false): Promise<void> {
    const sendUnlicensedMessageUrl = new URL(this.sendUnlicensedMessagePath, this.botEndpoint).toString();

    return axios.post(sendUnlicensedMessageUrl, {
      locale,
      forceSending
    }, {
      headers: await this.getHeaders()
    });
  }

  async sendWelcomeMessageToUserIfNotSentPreviously(locale: string): Promise<void> {
    if (localStorage.getItem("welcomeMessageSent")?.toLowerCase() === true.toString().toLowerCase()) {	
      return;
    }

    await this.sendWelcomeMessageToUser(locale);

    localStorage.setItem("welcomeMessageSent", true.toString());
  }

  async sendWelcomeMessageToUser(locale: string, forceSending: boolean = false): Promise<void> {
    const welcomeUserUrl = new URL(this.sendWelcomeMessagePath, this.botEndpoint).toString();

    return axios.post(welcomeUserUrl, {
      locale,
      forceSending
    }, {
      headers: await this.getHeaders()
    });
  }

  async resetUserState(): Promise<void> {
    localStorage.removeItem(this.unlicensedMessageSentKey);
    localStorage.removeItem(this.welcomeMessageSentKey);

    const resetUserStateUrl = new URL(this.debugForgetUserPath, this.botEndpoint).toString();

    return axios.post(resetUserStateUrl, {}, {
      headers: await this.getHeaders()
    });
  }

  private async getHeaders(): Promise<any> {
    return {
      Authorization: `Bearer ${await this.getToken()}`
    };
  }

  private async getToken(): Promise<string> {
    return (await this.credential.getToken(""))?.token || "";
  }
}

export default CoachConnector;