import { monetization } from "@microsoft/teams-js";
import { Button } from "react-bootstrap";

export const DebugMonetization = () => {
  const planInfo = {
    planId: "", // Plan Id of the published SAAS Offer
    term: "" // Term of the plan.
  }

  const handlePurchaseDialog = () => {
    // This method will invoke the purchase.
    monetization.openPurchaseExperience(planInfo);
  }

  return <div className="mt-4">
    <Button variant="secondary" onClick={handlePurchaseDialog}>Show purchase dialog</Button>
  </div>;
};
