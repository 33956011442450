import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { useContext } from "react";
import { TeamsFxContext } from "../../components/Context";
import { AzureFunctions } from "../../services/backend/AzureFunctions";
import { IUserIntakeProgress } from "../../services/backend/dtos/IUserIntakeProgress";
import { useNavigate } from "react-router-dom";

export enum UserIntakeQueryKeys {
    UseGetUserIntakeProgress = "useGetUserIntakeProgress"
}

export const useGetUserIntakeProgress = (): UseQueryResult<IUserIntakeProgress | undefined, Error> => {
    const navigate = useNavigate();

    const teamsUserCredential = useContext(TeamsFxContext).teamsUserCredential;
    if (!teamsUserCredential) {
        throw new Error("TeamsFx SDK is not initialized.");
    }

    return useQuery<IUserIntakeProgress | undefined, Error>({
        queryKey: [UserIntakeQueryKeys.UseGetUserIntakeProgress],
        queryFn: async () => {
            try {
                const userIntakeProgress = await new AzureFunctions(teamsUserCredential).getUserIntakeProgress();

                return userIntakeProgress;
            } catch (error) {
                if (error instanceof AzureFunctions.AuthorizationError) {
                    navigate("/unlicensed");
                } else {
                    throw error;
                }
            }
        },
        staleTime: 0,
    });
}