import { Button } from "react-bootstrap";
import { useCoachConnection } from "../../../hooks/coach-connector/useCoachConnection";
import { useLanguage } from "../../../hooks/language/useLanguage";

export const DebugCoachConnectionTool = () => {
  const { getCurrentLanguage } = useLanguage();
  const { sendWelcomeMessageToUser, sendUnlicensedMessageToUser } = useCoachConnection();

  const sendWelcomeMessage = async () => {
    const locale = getCurrentLanguage();

    await sendWelcomeMessageToUser(locale, /* forceSending: */ true);
  };

  const sendUnlicensedMessage = async () => {
    const locale = getCurrentLanguage();

    await sendUnlicensedMessageToUser(locale, /* forceSending: */ true);
  };

  return <div className="mt-4">
    <Button variant="secondary" onClick={sendWelcomeMessage}>Send user welcome message</Button>
    <br />
    <br />
    <Button variant="secondary" onClick={sendUnlicensedMessage}>Send user unlicensed message</Button>
  </div>;
};

