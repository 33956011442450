import { useContext } from "react";
import { TeamsFxContext } from "../../components/Context";
import CoachConnector from "../../services/coach/CoachConnector";

export const useCoachConnection = () => {
    const teamsUserCredential = useContext(TeamsFxContext).teamsUserCredential;

    if (!teamsUserCredential) {
        throw new Error("TeamsFx SDK is not initialized - cannot useCoachConnection.");
    }

    const coachConnector = new CoachConnector(teamsUserCredential);

    return {
        startIntake: coachConnector.startIntake.bind(coachConnector),
        startPractice: coachConnector.startPractice.bind(coachConnector),
        sendWelcomeMessageToUser: coachConnector.sendWelcomeMessageToUser.bind(coachConnector),
        sendUnlicensedMessageToUser: coachConnector.sendUnlicensedMessageToUser.bind(coachConnector),
        sendWelcomeMessageToUserIfNotSentPreviously: coachConnector.sendWelcomeMessageToUserIfNotSentPreviously.bind(coachConnector),
        sendUnlicensedMessageToUserIfNotSentPreviously: coachConnector.sendUnlicensedMessageToUserIfNotSentPreviously.bind(coachConnector),
        resetUserState: coachConnector.resetUserState.bind(coachConnector),
    };
};
