import { pages } from "@microsoft/teams-js";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useCoachConnection } from "../../../hooks/coach-connector/useCoachConnection";
import { useLanguage } from "../../../hooks/language/useLanguage";
import { useClearCurrentLessonSession } from "../../../hooks/lesson-session/UseLessonSession";
import { useResetUser } from "../../../hooks/user-profile/useUserInfo";

export const DebugIntakeTool = () => {
  const navigate = useNavigate();
  const { mutateAsync: resetUserAsync } = useResetUser();
  const { mutateAsync: clearCurrentLessonSessionAsync } = useClearCurrentLessonSession();
  const { getCurrentLanguage } = useLanguage();

  const { startIntake } = useCoachConnection();

  const openIntake = async () => {
    const locale = getCurrentLanguage();
    await startIntake(locale);
    pages.currentApp.navigateTo({ pageId: "conversations" });
  };

  const resetUser = async () => {
    await clearCurrentLessonSessionAsync();
    await resetUserAsync();
    navigate("/");
  };

  return <div className="mt-4">
    <Button variant="secondary" onClick={resetUser}>Reset user</Button>
    <br />
    <br />
    <Button variant="secondary" onClick={openIntake}>Start intake</Button>
  </div>;
};
